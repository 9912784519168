import { Auth } from 'aws-amplify';
import {
	from,
	ApolloClient,
	InMemoryCache,
	DefaultOptions,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

//const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
					locations
				)}, Path: ${path}`
			)
		);
	if (networkError) console.error(`[Network error]: ${networkError}`);
});

const setAuthorizationLink = setContext(async (_request, _previousContext) => {
	const user = await Auth.currentAuthenticatedUser();
	const token = user.getSignInUserSession().getAccessToken().getJwtToken();
	return {
		headers: {
			authorization: token ? `Bearer ${token}` : '',
			client_type: 'hustle-up-web',
			app_version: '0.1.0',
		},
	};
});


export const apolloCache = new InMemoryCache({
	typePolicies: {
		ChatMessage: {
			fields: {
				pending: {
					read() {
						return false;
					},
				},
			},
		},
		Query: {
			fields: {
				listProgramSessions:{
					keyArgs: false,
					merge(existing = {}, incoming) {
						if(!existing['__typename'])
							return incoming;

						if(existing?.result && incoming?.result) {
							let newResult = [...existing.result];

							incoming?.result.forEach((e: any) => {
								if (newResult.findIndex((i: any) => i?.__ref === e?.__ref) === -1) {
									newResult.push(e);
								}
							});

							return {
								...incoming,
								result: newResult
							}
						}

						return incoming;
					},
				}
			}
		}
	},
});

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'network-only',
		/* errorPolicy: 'ignore',*/
	},
};

export default new ApolloClient({
	//uri: process.env.REACT_APP_API_URL,
	cache: apolloCache,
	defaultOptions: defaultOptions,
	link: from([
		onErrorLink,
		setAuthorizationLink,
		// @ts-ignore
		new createUploadLink({
			uri: process.env.REACT_APP_API_URL,
		}),
	]),
});
